<template>
  <div class="item">
    <header>
      <ul v-if="couponlist.length>0">
        <li   v-for="(item, index) in couponlist" :key="index" @click="jumpGetCoupon">
          <div class="BgBox">
            <div  class="LeftBox">
                <p v-if="item.promotionMethod==0"> 
                  <sup>￥</sup>{{ item.promotionValue }}
                </p>
                <p v-if="item.promotionMethod==1"> 
                  {{ item.promotionValue }}<sub>折</sub>
                </p>
                <p>{{ item.couponName}}</p>
            </div>
            <div  class="RightBtn">
                <img src="@/assets/GetBtn.png" alt="">
            </div>
          </div>
        </li>
      </ul>
     
       <el-col v-else :span="24">
         <el-col :span="6" >
           <div class="UserPic">
             {{name.substring(0,1)}}
           </div>
         </el-col>
         <el-col :span="18">
          <p class="NameTit"><span class="nameMsg" :title="name">{{name}} </span>
          </p>
            <div class="Tagbox">
              <div class="light"></div>
              <span  class="TagMsg">{{memberLevelName}}</span>
            </div>
           <!-- <p><span class="nameMsg">{{name}} </span>
              <el-tag
                size="mini" 
                class="TagMsg"
                effect="dark">
                {{memberLevelName}}
              </el-tag>
          </p> -->
         </el-col>
         <div class="ulbox" v-if="StoreInfo!=''">
            <div class="libox" @click="jumpMy" >
              <span>{{StoreInfo.balance}}</span>
              <span>预存款</span>
            </div>
            <div class="libox" @click="jumpMy" >
              <span>{{StoreInfo.couponCount}}</span>
              <span>优惠券</span>
            </div>
            <div class="libox" @click="jumpMy" >
              <span>{{StoreInfo.integral}}</span>
              <span>我的药豆</span>
            </div>
            <div class="libox"  @click="jumpMyorder" >
              <span>{{StoreInfo.waitOrderCount}}</span>
              <span>待收货</span>
            </div>
            <div class="libox">
              <span>{{StoreInfo.alwaysBuyCount}}</span>
              <span>常购品种</span>
            </div>
            <div class="libox">
              <span>{{StoreInfo.notBuyCount}}</span>
              <span>未购品种</span>
            </div>
          </div>
      </el-col>

    

    </header>
    <center>
      <ul>
        <li><span @click="jumpGetCoupon"><img src="@/assets/CouponCenter_Icon.png" alt=""></span><span>领券中心</span></li>
        <!-- <li><span  @click="jumpOftenBuy"><img src="@/assets/RecentPurchas_Icon.png" alt=""></span><span>最近购买</span></li> -->
        <li><span  @click="jumpQuickOrder"><img src="@/assets/QuickOrder_Icon.png" alt=""></span><span>快速下单</span></li>
        <li><span @click="JumpIntegralMallList"><img src="@/assets/PointsMall _Icon.png" alt=""></span><span>药豆商城</span></li>
      </ul>

    </center>
    <main>
      <el-col :span="24">
        <el-col :span="12" class="text-left title">每日公告</el-col>
        <!-- <el-col :span="12" class="text-right more"><div @click="jumpCurrentMessage">更多<i class="el-icon-arrow-right" aria-hidden="true"></i></div></el-col> -->
      </el-col>
      <ul>
        <li v-for="(item,index) in articlesList" :key="index" class="text-overflow"  @click="jumpInfoDetail(item.id)">
            <span>{{item.title}}</span>
        </li>
        <!-- <li
          v-for="(item, index) in list.NoticeList"
          :key="index"
          class="text-overflow"
          @click="jumpInfoDetail(item.id)"
        >
          <span>{{ item.title }}</span>
        </li> -->
      </ul>
    </main>
    <!-- <footer  @click="jumpGetCoupon">
       <img :src="CouponCenterBg" alt="" /> -->
      <!-- <p>手机端敬请期待</p> -->
      <!-- app二维码 -->
      <!-- <div class="text-center">
        <img :src="IosCode" alt="" />
      </div> -->
<!--      
      <div class="text-center">
         <el-col
            :span="24"
            v-for="(item, index) in srcList"
            :key="index"
            :offset="index > 0 ? 0 : 0"
          >
            <el-card
              class="image__preview"
              :body-style="{ padding: '0px' }"
            >
              <div class="block">
                <el-image
                  style="width: 100%; height:auto"
                  :src="item"
                  :preview-src-list="srcList"
                >
                </el-image>
              </div>
            </el-card>
          </el-col>
      </div> -->
    <!-- </footer> -->
<el-dialog
  id="infoBox"
  :title="list.infoDetail.title"
  :visible.sync="flag"
  width="26%"
 >
  <span>{{list.infoDetail.description}}</span>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="flag = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>
<style lang="less">
#infoBox{
  .el-dialog{
    background: url('../../assets/alertBg.png') no-repeat,#fff;
    background-size:100% auto;
    padding-top:70px;
    border-radius: 10px;
    .el-dialog__header{
        padding: 10px 20px 0px;
    }
    .el-dialog__body{
      padding:10px 20px;
    }
    .el-dialog__close{
      display: none;
    }
    .dialog-footer{
       .el-button{
            margin: 0 auto!important;
          display: block!important;
          background: #ff4800!important;
          border-radius: 30px!important;
          border: 1px solid #ff4800!important;
          padding: 10px 50px;
        }
      }
  }
  
}

</style>
<style lang="less" scoped>
@import "~style/index.less";
.UserPic{
   width: 42px;
  height: 42px;
  border:1px solid #FADDA8;
  border-radius: 50%;
  background: #FADDA8;
  color: #F43C38;
  line-height: 42px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.item {
  box-sizing: border-box;
  header {
    padding: 0px 10px;
    height:212px;
    background: #fff;
    ul{
      li{
        width: 100%;
        height:62px;
        padding:2px 0;
        &:nth-child(1){
          padding-top:10px;
        }
        .BgBox{
          width: 100%;
          height: 100%;
          .LeftBox{
              width: 132px;
              height: 52px;
              float: left;
              padding: 5px 0;
              p{
                line-height: 26px;
                text-align: center;
                color: #fff;
                sub{
                  font-size: 12px;
                  font-weight: normal;
                  // vertical-align:sub;
                }
                sup{
                  font-size: 12px;
                  font-weight: normal;
                  vertical-align:super;
                }
              }
              p:nth-child(1){
                font-size:20px;
                font-weight: bold;
              }
               p:nth-child(2){
                font-size: 12px;
                white-space:nowrap;
                text-overflow:ellipsis;
                overflow:hidden;
                padding: 0 5px;
              }
          }
          .RightBtn{
            width: 85px;
            height: 100%;
            float: right;
            position: relative;
            img{
              position: absolute;
              display: inline-block;
              height:24px;
              width: 62px;
              top:18px;
              left:10px;
            }
            
          }
        }
        &:nth-child(1) .BgBox{
          background: url('../../assets/Coupon_bg1.png') no-repeat;
          background-size: 100% 100% ;
        }
        &:nth-child(2) .BgBox{
          background: url('../../assets/Coupon_bg2.png') no-repeat;
          background-size: 100% 100% ;
        }
        &:nth-child(3) .BgBox{
          background: url('../../assets/Coupon_bg3.png') no-repeat;
          background-size: 100% 100% ;
        }
      }
    }

    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    line-height: 20px;
    font-weight: 600;
    .nameMsg{
      float: left;
      font-weight: bold;
      font-size:12px;
      padding-right:4px ;
      white-space:nowrap;
      text-overflow:ellipsis;
      overflow:hidden;
      width: 90%;
      cursor: pointer;
    }
    .NameTit{
      width: 100%;
      height: 26px;
    }
    .Tagbox{
      position: relative;
				overflow: hidden;
        .light{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left:-5px;
          height: 20px;
          width: 3px;
          transform: rotate(-45deg);
          background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(255 255 255), rgba(0, 0, 0, 0));
          /* 过度实现部分 */
          transition: 1.3s;
        }
        &:hover .light{
          /* 过度实现部分 */
          /* transform: translate(200%,200%) rotate(-45deg); */
          animation: cross 0.5s;
        }
       
        @keyframes cross{
            from{
              left: -5px;
              
            }
            to{
              left:100px;
              transform: rotate(-45deg);
            }
          }
    }
    .TagMsg{
      margin-top:5px;
      padding:1px 6px;
      background: #1C1C19 ;
      color: #FADDA8;
      border:none;
      border-radius:10px;
    }
    // .acount {
    //   float: right;
    //   color: #F43C38 ;
    //   padding-left: 10px;
    //   cursor: pointer;
    //   &:hover {
    //     color: @theme;
    //   }
    // }
    // h2 {
    //   font-size: 14px;
    //   color: #666;
    //   line-height: 17px;
    //   font-weight: normal;
    //   letter-spacing: 1px;
    // }
    // .btnWraper {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   .btn {
    //     background: @theme;
    //     color: #fff;
    //     width: 85px;
    //     height: 30px;
    //     line-height: 30px;
    //     margin-top: 24px;
    //   }
    // }
      .ulbox{
       padding-top:10px;
       display: inline-block;
      .libox{
        cursor: pointer;
        float: left;
        width: 33%;
        padding-top: 5px;
        &:nth-child(5){
          cursor:not-allowed;
        }
        &:nth-child(6){
          cursor:not-allowed;
        }
        span:nth-child(1){
          display: inline-block;
          width: 100%;
          padding:5px 0 0 0;
          text-align: center;
        }
        span:nth-child(2){
          text-align: center;
          color: #666666;
          font-weight: bold;
          font-size: 11px;
          width: 100%;
          display: inline-block;
        }
      }
    }
  }
  center{
    padding:0 10px;
    height:72px;
    background: #fff;
    ul{
       border-top: 2px solid #efefef;
      li{
        cursor: pointer;
        float: left;
        width: 33%;
        span:nth-child(1){
          display: inline-block;
          width: 100%;
          padding:5px 0 0 0;
          img{
            width:36px;
            height:36px;
          }
        }
        span:nth-child(2){
          text-align: center;
          color: #666666;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
  main {
    padding:0 8px;
    height:146px;
    background: #fff;
    overflow: hidden;
    .title {
      border-top: 2px solid #efefef;
      color: #333;
      font-size: 15px;
      line-height:40px;
      font-weight: 600;
        width: 100%;
    }
    .more {
      border-top: 2px dashed #efefef;
      color: #999;
      font-weight: 600;
      font-size: 13px;
      line-height:40px;
      cursor: pointer;
      &:hover {
        color: @theme;
      }
    }
    ul {
      padding-top: 10px;
      padding-bottom: 9px;
      float: left;
      li {
        line-height: 20px;

        span {
          color: #999;
          font-size: 12px;
          letter-spacing: 1px;
          cursor: pointer;
          &:hover {
            color: @theme;
          }
        }
      }
    }
  }
  footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height:146px;
    margin-top:10px;

    img{
      width: 100%;
      height:146px;
    }
    // /deep/ .image__preview{
    //    width: 90px;
    //   height: 114px;
    //   display: block;
    // }
  }
}
</style>

<script>

import { getMemberStoreInfo } from "api/home.js"; 
import {GetCouponList} from 'api/member.js';
import CouponCenterBg from "assets/CouponCenterBg.png";
import IosCode from "assets/wyios_down.png";
import AZCode from "assets/QRcode.png";
// import { GetMemberStore } from "api/member.js";
import { getAritcledDetail } from "api/product.js";   //articleList, 
import { mapMutations} from "vuex";
export default {
  name: "RightNav",
   inject:['reload'],
  data() {
    return {
       flag:false,
        couponlist:[],
       StoreInfo:'',
      IosCode: IosCode,
      AZCode:AZCode,
      CouponCenterBg:CouponCenterBg,
       srcList: [AZCode],
      // srcList: [IosCode,AZCode],
      list: {
        // NoticeList: [],
        infoDetail: "",
       
      },
    };
  },
  props: {
    name: {
      type: String,
    },
    memberLevelName: {
      type: String,
    },
    articlesList: {
      type: Array,
    },
  },
  watch: {
    articlesList: function() {
      //监听props中的属性
      // newVal
    },
  },
  mounted() {
    // this.getInfo();
  },
  created() {


     //  获取优惠券列表
    let params={
          current:1,
          size:3
        }
        GetCouponList(params).then(data=>{
              this.couponlist=data.data.data.slice(0,3)
              // console.log('优惠券列表数据',this.couponlist)
            })
      //  GetMemberStore().then((res) => {
      //     if(res.data.data.length>1){
      //           this.BtnShow=true
      //     }else{
      //       this.BtnShow=false
      //     }
      // });

      getMemberStoreInfo().then(res=>{
        this.StoreInfo=res.data.data
      })
  },
  methods: {
    JumpIntegralMallList(){
        this.$router.push({name:"IntegralMallList",query:{type:9}})
    },
     jumpMy() {
        this.$router.push({
          name: "MyAccount"
        });
      },
      jumpMyorder() {
        this.$router.push({
          name: "MyOrder"
        });
      },
     jumpGetCoupon(){
       this.$router.push({ name: "GetCoupon" });
    },
    jumpOftenBuy(){
       this.$router.push({ name: "MyOftenBuy" });
    },
     // 快速下单
     jumpQuickOrder(){
        this.$router.push({ name: "QuickOrder" });
    },
    jumpInfoDetail(infoId) {
      // 文章详情
      getAritcledDetail(infoId).then((res) => {
        this.$set(this.list, "infoDetail", res.data.data);
          this.flag=true;
        // this.$alert(
        //   this.list.infoDetail.description,
        //   this.list.infoDetail.title,
        //   {
        //     dangerouslyUseHTMLString: true,
        //   }
        // ).catch(e=>e);//捕获取消事件，执行
      });
    },
    jumpLogin() {
      this.$router.push({ name: "Login" });
    },
    jumpRegister() {
      this.$router.push({ name: "Register" });
    },
    jumpCurrentMessage() {
      this.$router.push({ name: "CurrentMessage" });
    },
    
    ...mapMutations({
        memberName:"memberName",
        setCarGoodsNum: "setCarGoodsNum",
    }),
  },

  components: {},
  computed: {},
};
</script>
